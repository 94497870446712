<template>
    <Menu
        :loggedIn="loggedIn"/>
    <router-view
        v-on:changeLoginStatus="loggedIn = $event"
    />
    <div class="loadingBar"></div>
</template>

<script>
import Menu from '@/components/Menu.vue'
import axios from 'axios'

export default {
    name: "App",
    data(){
        return {
            loggedIn: false
        }
    },
    components: {
        Menu
    },
    created(){
        this.initNightmode();
        this.verifyUser();
    },
    methods: {
        initNightmode(){
            let nightmode = localStorage.getItem('nightmodeEnabled');
            if(nightmode == 'true'){
                localStorage.setItem('nightmodeEnabled', true);
                document.body.classList.add('nightmode');
            }else{
                localStorage.setItem('nightmodeEnabled', false);
                document.body.classList.remove('nightmode');
            }
        },
        verifyUser(){
            axios.get('/api/v1/login/status/')
            .then(response => {
                if(response.data.success){
                    if(!response.data.authorized){
                        if(this.$route.name == 'Register'){
                            //allow register without login
                        }else{
                            this.$router.push('/login');
                        }
                    }else{
                        this.loggedIn = true;
                    }
                }
            })
            .catch(error => {
                console.log(error);
            });
        }
    }
}

</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&display=swap');
    @import url('https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp');
    
    *{
        position: relative;
        box-sizing: border-box;
        padding: 0px;
        margin: 0px;
        /* box-shadow: 0px 0px 2px 2px green; */
    }
    :root{
        --first-cl: #000;
        --second-cl: #fff;
        --dark-bg: #181A1B;
    }

    html, body{
        position: fixed;
        left: 0px;
        top: 0px;
        width: 100vw;
        height: 100vh;
        overflow: auto;
        font-family: 'Roboto', sans-serif;
        transition: background-color .4s ease;
    }
    html{
        overflow: hidden;
    }

    #app{
        left: 50%;
        top: 0%;
        width: 100%;
        max-width: 1600px;
        transform: translateX(-50%);
        min-height: 100vh;
        padding: 8px;
    }

    .loadingBar{
        position: fixed;
        top: 0px;
        left: 0px;
        width: 0%;
        height: 4px;
        background-color: #f00;
        transition: cubic-bezier(0,1,0,1);
        transition-duration: 15s;
        z-index: 100;
    }
    .loadingBarEnd{
        transition: cubic-bezier(0,1,0,1);
        transition-duration: 1s;
    }

    img{
        object-fit: cover;
        transition: brightness .4s ease;
    }
    a{
        color: var(--first-cl);
    }
    button{
        background-color: var(--dark-bg);
        color: #fff;
        display: flex;
        align-items: center;
        flex-direction: row;
        padding: 8px 12px;
        border: none;
        border-radius: 5px;
        margin: 5px 0px;
        cursor: pointer;
        max-width: 100%;
        white-space: nowrap;
    }
    input, select{
        background-color: var(--dark-bg);
        color: #fff;
        padding: 8px 12px;
        border: none;
        border-radius: 5px;
        margin: 5px 0px;
        cursor:text;
        max-width: 100%;
        white-space: nowrap;
    }
    button>span{
        margin: 0px 8px 0px 0px;
        white-space: nowrap;
    }

    .content{
        padding: 8px;
    }

    .nightmode{
        background-color: var(--dark-bg);
        color: #fff;
    }
    .nightmode a{
        color: #fff;
    }
    .nightmode img{
        filter: brightness(0.8);
    }
    .nightmode input,
    .nightmode select,
    .nightmode button{
        background-color: rgb(43, 43, 43);
        color: #fff;
    }
</style>
