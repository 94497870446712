<template>
    <div class="menu">
        <h1>
            <span class="material-icons-outlined cloudIcon">
            cloud
            </span>
            <span>
                Unseld-Cloud
            </span>
        </h1>
        <ul :class="`list ${(hideMenu)?'hideMenu':''}`" v-if="loggedIn === true">
            <li @click="hideMenu=true">
                <router-link to="/">
                    <span class="material-icons-round">
                        home
                    </span>
                    <span>
                        Startseite
                    </span>
                </router-link>
            </li>
            <li @click="hideMenu=true">
                <router-link to="/album">
                    <span class="material-icons-round">
                        collections
                    </span>
                    <span>
                        Alben
                    </span>
                </router-link>
            </li>
            <li @click="hideMenu=true">
                <router-link to="/edit">
                    <span class="material-icons-round">
                        edit
                    </span>
                    <span>
                        Bearbeiten
                    </span>
                </router-link>
            </li>
            <li @click="hideMenu=true" v-if="nightmodeEnabled === false">
                <a v-on:click="toggleNightmode()">
                    <span class="material-icons-round">
                        nights_stay
                    </span>
                    <span>
                        Nachtmodus
                    </span>
                </a>
            </li>
            <li @click="hideMenu=true" v-if="nightmodeEnabled === true">
                <a v-on:click="toggleNightmode()">
                    <span class="material-icons-round">
                        light_mode
                    </span>
                    <span>
                        Hellmodus
                    </span>
                </a>
            </li>
        </ul>
        <div class="menuButton" @click="hideMenu?hideMenu=false:hideMenu=true">
            <span class="material-icons-round">
                menu
            </span>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            nightmodeEnabled: false,
            hideMenu: true
        }
    },
    props: [
        'loggedIn'
    ],
    created(){
        this.initNightmode();
    },
    methods: {
        toggleNightmode(){
            let nightmode = localStorage.getItem('nightmodeEnabled');
            if(nightmode == 'true'){
                document.body.classList.remove('nightmode');
                localStorage.setItem('nightmodeEnabled', false);
                this.nightmodeEnabled = false;
            }else{
                document.body.classList.add('nightmode');
                localStorage.setItem('nightmodeEnabled', true);
                this.nightmodeEnabled = true;
            }
        },
        initNightmode(){
            let nightmode = localStorage.getItem('nightmodeEnabled');
            if(nightmode == 'true'){
                this.nightmodeEnabled = true;
            }else{
                this.nightmodeEnabled = false;
            }
        }
    }
}
</script>

<style scoped>
    .menu{
        display: flex;
        flex-direction: row;
        margin: 30px 0px;
        align-items: center;
    }
    .menu h1{
        display: flex;
        margin-right: auto;
        align-items: center;
    }
    .list{
        margin-left: auto;
        display: flex;
        flex-direction: row;
        list-style-type: none;
    }
    .list a{
        text-decoration: none;
        display: inline-flex;
        padding: 10px;
        margin: 0px 10px;
        align-items: center;
        cursor: pointer;
    }
    .cloudIcon{
        font-size: 44pt;
    }
    span{
        display: inline-block;
        margin: 0px 0px 0px 15px;
    }

    .menuButton{
        position: fixed;
        top: 10px;
        right: 10px;
        z-index: 9999;
        cursor: pointer;
        display:none;
    }
    .menuButton > span{
        pointer-events: none;
        user-select: none;
    }

    @media (max-width: 1100px){
        .list{
            position: fixed;
            right: 0px;
            top: 0px;
            padding: 20px;
            background: #fff;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, .5);
            max-width: 100%;
            flex-direction: column;
            z-index: 999;
            display:block;
        }

        .menuButton{
            display:block;
        }

        .hideMenu{
            display: none;
        }
    }
    .nightmode .list{
        background: rgb(19, 19, 19);
        box-shadow: 0px 0px 10px rgb(0, 0, 0);
    }
</style>