import { createRouter, createWebHashHistory } from 'vue-router'
// import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/album',
    name: 'Album',
    component: () => import('../views/Album.vue')
  },
  {
    path: '/album/:albumId',
    name: 'AlbumEdit',
    component: () => import('../views/AlbumEdit.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/invite/:token',
    name: 'Register',
    component: () => import('../views/Register.vue')
  },
  {
    path: '/edit',
    name: 'Edit',
    component: () => import('../views/Edit.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach(() => {
  console.log('start')
  let bar = document.querySelector('.loadingBar');
  bar.style.transitionDuration = '15s';
  setTimeout(()=>{
    bar.classList.remove('loadingBarEnd');
    bar.style.width = '70%';
  }, 100);
});

router.afterEach(() => {
  console.log('end')
  setTimeout(()=>{
    let bar = document.querySelector('.loadingBar');
    bar.style.transitionDuration = '1s';
    bar.classList.add('loadingBarEnd');
    bar.style.width = '100%';
    setTimeout(()=>{
      bar.style.transitionDuration = '0s';
      bar.style.width = '0%';
    }, 200);
  }, 400);
});

export default router
